var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"wizard wizard-2",attrs:{"id":"kt_wizard_v2","data-wizard-state":"step-first","data-wizard-clickable":"true"}},[_c('div',{staticClass:"wizard-body py-8 px-8 py-lg-10 px-lg-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h4',[_vm._v("Roles")])]),_c('div',{staticClass:"col-6 text-right"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.rebootPermission}},[_c('i',{staticClass:"mx-3 fas fa-sync"}),_vm._v(" Reboot Permission ")])],1),_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table table-stripe"},[_c('thead',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Name")]),_c('th',{staticClass:"text-center"},[_vm._v("Action")])]),_c('tbody',_vm._l((_vm.roles),function(role,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[(role.name && _vm.checkIsAccessible('role', 'list'))?_c('span',[_c('a',{on:{"click":function($event){return _vm.managePermission(role.id)}}},[_vm._v(_vm._s(role.name_text)+" ")])]):_c('span',[_vm._v("-")])]),_c('td',[[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[(
                                    _vm.checkIsAccessible(
                                      'role',
                                      'manage-permission'
                                    )
                                  )?_c('v-list-item',{staticClass:"cursor-pointer blue--text",on:{"click":function($event){return _vm.managePermission(role.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue darken-2","small":""}},[_vm._v(" mdi-settings ")]),_vm._v(" Manage Permission ")],1)],1):_vm._e(),(
                                    _vm.checkIsAccessible(
                                      'role',
                                      'manage-custom-permission'
                                    )
                                  )?_c('v-list-item',{staticClass:"cursor-pointer blue--text",on:{"click":function($event){return _vm.manageCustomPermission(role.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue darken-2","small":""}},[_vm._v(" mdi-settings ")]),_vm._v(" Manage Custom Permission ")],1)],1):_vm._e()],1)],1)],1)]],2)])}),0)])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
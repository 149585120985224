<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h4>Roles</h4>
              </div>
              <div class="col-6 text-right">
                <v-btn
                  color="primary"
                  @click="rebootPermission"
                  :loading="loading"
                >
                  <i class="mx-3 fas fa-sync"></i>
                  Reboot Permission
                </v-btn>
              </div>
              <div class="col-12">
                <table class="table table-stripe">
                  <thead>
                    <th>#</th>
                    <th>Name</th>
                    <th class="text-center">Action</th>
                  </thead>
                  <tbody>
                    <tr v-for="(role, index) of roles" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <span
                          v-if="role.name && checkIsAccessible('role', 'list')"
                        >
                          <a @click="managePermission(role.id)"
                            >{{ role.name_text }}
                          </a>
                        </span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <template>
                          <div class="text-center">
                            <v-menu left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  icon
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-if="
                                    checkIsAccessible(
                                      'role',
                                      'manage-permission'
                                    )
                                  "
                                  class="cursor-pointer blue--text"
                                  @click="managePermission(role.id)"
                                >
                                  <v-list-item-title>
                                    <v-icon
                                      color="blue darken-2"
                                      small
                                      class="mr-2"
                                    >
                                      mdi-settings
                                    </v-icon>
                                    Manage Permission
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  v-if="
                                    checkIsAccessible(
                                      'role',
                                      'manage-custom-permission'
                                    )
                                  "
                                  class="cursor-pointer blue--text"
                                  @click="manageCustomPermission(role.id)"
                                >
                                  <v-list-item-title>
                                    <v-icon
                                      color="blue darken-2"
                                      small
                                      class="mr-2"
                                    >
                                      mdi-settings
                                    </v-icon>
                                    Manage Custom Permission
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import AdminUserService from "@/service/user/AdminUserService";
import RoleService from "@/service/user/role/RoleService";
import RoleCustomPermission from "@/service/user/role/RoleCustomPermission";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const adminUserService = new AdminUserService();
const roleService = new RoleService();
const customPermission = new RoleCustomPermission();

export default {
  name: "users",
  components: {},
  data() {
    return {
      roles: [],
      user: {},
      total: null,
      perPage: null,
      loading: false
    };
  },
  computed: {
    user_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getRoles();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Role" },
      { title: "Manage Role" }
    ]);
  },
  methods: {
    userRoles() {
      adminUserService.userRoles(this.user_id).then(response => {
        this.user_roles = response.data;
        this.selected_roles = [];
        this.user_roles.forEach(element => {
          this.selected_roles.push(element);
        });
      });
    },
    getUser() {
      adminUserService.show(this.user_id).then(response => {
        this.user = response.data.user;
      });
    },
    getRoles() {
      roleService.all().then(response => {
        this.roles = response.data.data;
      });
    },
    managePermission(id) {
      this.$router.push({ name: "role-permission", params: { id: id } });
    },
    manageCustomPermission(id) {
      this.$router.push({ name: "custom-permission", params: { id: id } });
    },
    deleteUserRole(role) {
      this.$confirm({
        message: `Are you sure ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            adminUserService
              .removeRole(this.user_id, { roles: [role] })
              .then(response => {
                if (response.data.status === "OK") {
                  this.$snotify.success("Deleted successfully");
                  this.userRoles();
                }
              })
              .catch(() => {});
          }
        }
      });
    },
    assignRole() {
      adminUserService
        .assignRole(this.user_id, { roles: this.selected_roles })
        .then(response => {
          if (response.data.status === "OK") {
            this.user_roles = [];
            this.userRoles();
          }
        });
    },
    rebootPermission() {
      this.$confirm({
        message: `Are you sure ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.loading = true;
            customPermission
              .rebootPermission()
              .then(response => {
                if (response.data) {
                  this.loading = false;
                  this.$snotify.success(
                    "User Role And Permission Successfully Rebooted"
                  );
                  this.$store.dispatch("GET_USER_PERMISSIONS");
                } else {
                  this.$snotify.error("Oops, Problem Occurred !!");
                }
              })
              .catch(() => {
                this.loading = false;
                this.$snotify.error("Oops, Problem Occurred !!");
              });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item:hover {
    background: #e9ebf4;
  }
}
</style>
